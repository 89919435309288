import {
    CallbackNameList,
    ComponentType,
    Constants,
    DeepPartial,
    Field,
    InputMode,
    InputType,
    IValidateObject,
    IValidateUpptecDataParams,
    pathof,
    PlaceTypes,
    Questionnaire,
    REGEXP,
    ValidationFunctionNameList,
    yearFormat,
    yearMonthDayFormat
} from "@folksam-digital/model";
import moment from "moment";
import {IComponentMapping} from "../product/GeneratorConfig";
import {ICmsParams} from "../IGenerator";
import MobilePhoneJourneyIditModel from "@folksam-digital/model/lib/model/MobilePhoneJourneyIditModel";
import TheftJourneyIditModel from "@folksam-digital/model/lib/model/TheftJourneyIditModel";
import UpptecClaimResponseModel from "@folksam-digital/model/lib/model/UpptecClaimResponseModel";
import OtherDamageOrLossJourneyIditModel from "@folksam-digital/model/lib/model/OtherDamageOrLossJourneyIditModel";
import AccidentDuringTravelJourneyIditModel
    from "@folksam-digital/model/lib/model/AccidentDuringTravelJourneyIditModel";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import IllnessDuringTravelJourneyIditModel from "@folksam-digital/model/lib/model/IllnessDuringTravelJourneyIditModel";
import ClaimJourneyModel from "@folksam-digital/model/lib/model/BicycleJourneyIditModel";
import {IPath} from "@folksam-digital/model/lib/utils/pathof";


const setCustomValidationForSSN = (modelPath: IPath) => {
    return {
        function: ValidationFunctionNameList.validateIsObjectNotEmpty,
        params: {
            fields: [pathof(modelPath)],
            errors: {required: "general.form.error.required"}
        } as IValidateObject
    }
}

export const inputTypeMapping: IComponentMapping = {
    [`${Constants.Questionnaire.InputTypes.TextBox}`]: {
        component: ComponentType.TextBox,
        maxLength: 100
    },
    [`${Constants.Questionnaire.InputTypes.ToggleButton}`]: {
        component: ComponentType.ToggleButton,
        componentMetadata: {
            groupVertically: true
        } as any
    }
};

export const questionMapping = (cmsParams: ICmsParams, index?: number): IComponentMapping => {
    const upptecDependencies = {
        [`${Constants.Journey.MobilePhone.Id}`]: `questionnaire_1100173_damage_phones_${index}_questionnaireDamagedItems`,
        [`${Constants.Journey.OtherDamageOrLoss.Id}`]: `questionnaire_1100173_damage_mobilePhone_${index}_questionnaireDamagedItems`,
        [`${Constants.Journey.Theft.Id}`]: `questionnaire_1100173_damage_mobilePhone_${index}_questionnaireDamagedItems`
    };
    const upptecDataPaths = {
        [`${Constants.Journey.MobilePhone.Id}`]: `${pathof(MobilePhoneJourneyIditModel.damage.phones.at(index || 0).questionnaireDamagedItems)}._1100173.upptecData`,
        [`${Constants.Journey.OtherDamageOrLoss.Id}`]: `${pathof(OtherDamageOrLossJourneyIditModel.damage.mobilePhone.at(index || 0).questionnaireDamagedItems)}._1100173.upptecData`,
        [`${Constants.Journey.Theft.Id}`]: `${pathof(TheftJourneyIditModel.damage.mobilePhone.at(index || 0).questionnaireDamagedItems)}._1100173.upptecData`
    };

    const upptecDataValidationParams: IValidateUpptecDataParams = {
        dependencies: [upptecDependencies[cmsParams?.catalogName]],
        upptecDataPath: upptecDataPaths[cmsParams?.catalogName],
        upptecFieldPaths: [
            pathof(UpptecClaimResponseModel.calculationDetails.current_market_price.amount),
            pathof(UpptecClaimResponseModel.originalProduct.name),
            pathof(UpptecClaimResponseModel.originalProduct.brand)
        ]
    };

    const delayedTravellerEnterPersonalNumberMetadata = {
        dataPath: [
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber1}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber2}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber3}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber4}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber5}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber6}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber7}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber8}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber9}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber10}`).contact.ssn),
        ],
        placeholder: "general.form.personSsnNumberContainer.placeholder",
        disableTitleSuffix: true,
        ssnInUseError: "claim.delayedTraveler.companion.participants.error.ssnAlreadyInUse",
    };

    const cancelledTravelEnterPersonalNumberMetadata = {
        dataPath: [
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber1}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber2}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber3}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber4}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber5}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber6}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber7}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber8}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber9}`).contact.ssn),
            pathof(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber10}`).contact.ssn),
        ],
        placeholder: "general.form.personSsnNumberContainer.placeholder",
        disableTitleSuffix: true,
        ssnInUseError: "claim.cancelledTravel.companion.participants.error.ssnAlreadyInUse",
    };

    const delayedLuggageHowManyHoursDelay = Object.assign({}, ...[
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100191,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100200,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100201,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100202,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100203,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100204,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100205,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100206,
        Constants.Questionnaire.QuestionTypes.DelayedLuggage.HowManyHoursDelay1100207
    ].map((questionId, i) => ({
            [questionId]: {
                title: "claim.delayedLuggage.hoursOfDelay.title",
                component: ComponentType.RadioGroupCard,
                errorMessages: {
                    type: "general.form.error.required",
                    required: "general.form.error.required"
                },
                componentMetadata: {
                    translateTitle: true,
                    bordered: true,
                    index: i
                }
            }
        })
    ));

    return  {
        [Constants.Questionnaire.QuestionTypes.MobilePhone.IMEINumberQuestionId]: {
            component: ComponentType.TextBox,
            minLength: 1,
            maxLength: 15,
            errorMessages: {
                maxLength: "claim.mobilePhone.questionnaire.1100257.error.pattern",
                pattern: "claim.mobilePhone.questionnaire.1100257.error.pattern"
            },
            componentMetadata: {
                type: InputType.number
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.DescribeDamageQuestionId]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.PhoneModelQuestionId]: {
            component: ComponentType.UpptecSearchInput,
            validation: {
                function: ValidationFunctionNameList.validateUpptecData,
                params: upptecDataValidationParams
            } as any,
            errorMessages: {
                type: "general.form.error.required",
                required: "general.form.error.required"
            },
            componentMetadata: {
                suggestionFields: ["brand", "name"],
                searchPlaceholderText: "claim.mobilePhone.aboutMobilePhone.typeOfPhone.placeholder"
            } as any
        },
        [`${Constants.Questionnaire.QuestionTypes.DelayedLuggage.EstimatedValueDamagedLuggage}`]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [`${Constants.Questionnaire.QuestionTypes.DelayedLuggage.EstimatedValueStolenItems}`]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [`${Constants.Questionnaire.QuestionTypes.DelayedLuggage.EstimatedValueBags}`]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        ...delayedLuggageHowManyHoursDelay,
        [Constants.Questionnaire.QuestionTypes.Bicycle.ItemTypeBicycleModel]: {
            component: ComponentType.TextBox,
            componentMetadata: {
                help: null
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.ItemTypePhoneBrandQuestionId]: {
            component: ComponentType.Hidden
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.ItemTypePhonePriceQuestionId]: {
            component: ComponentType.Hidden
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.DropDownMultiSelectTypeOfItem1100255]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.MobilePhone.DropDownMultiSelectTypeOfItem1100643]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100359]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100356]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100357]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100358]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100687]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100688]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100689]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100690]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100691]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100692]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.DropDownMultiSelectTypeOfItem1100693]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText",
                isSearchable: true
            }
        },
        [Constants.Questionnaire.QuestionTypes.OtherDamageOrLoss.TextareaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.HouseholdAppliances.DropDownMultiSelectWhatHasBeenDamaged]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.HouseholdAppliances.DropDownMultiSelectWhatHasBeenDamagedRental]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.HouseholdAppliances.TextareaDescribeTheError]:{
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101002]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101003]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101007]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101008]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101033]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101034]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101035]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101036]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101037]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101038]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101039]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101040]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101041]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.DropDownMultiSelectWhatHasBeenStolen1101042]: {
            component: ComponentType.DropDownMultiSelect,
            componentMetadata: {
                help: "general.claim.questionnaire.additionalItems.helpText"
            }
        },
        [Constants.Questionnaire.QuestionTypes.Theft.TextareaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Nature.DescribeTheEvent1100592]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Nature.DescribeDamages]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Glass.TextareaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Glass.RepairCost]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Storm.TextareaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DescribeDamages]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100721]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100722]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100723]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100724]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100725]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Storm.DropDownMultiSelectWhatHasBeenDamaged1100726]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100952]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100953]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100954]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100955]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100956]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100957]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100958]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100959]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100960]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100961]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100962]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100963]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DropDownMultiSelectWhatHasBeenDamaged1100964]: {
            component: ComponentType.DropDownMultiSelect
        },
        [Constants.Questionnaire.QuestionTypes.Water.DescribeDamages]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Burglary.DescribeDamages]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Burglary.TextareaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.HowManyPersonsAreAffectedByTheEvent]: {
            component: ComponentType.DropDown
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber1]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber1}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber2]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber2}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber3]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber3}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber4]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber4}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber5]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber5}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber6]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber6}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber7]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber7}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber8]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber8}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber9]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber9}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.DelayedTraveler.EnterPersonalNumber10]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.delayedTraveler.aboutEvent.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: delayedTravellerEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.DelayedTraveler.EnterPersonalNumber10}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.AccidentSpareTime.TripDurationDays]: {
            component: ComponentType.TextBox,
            errorMessages: {
                pattern: "general.form.error.required"
            }
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.SearchableWhereDidYouSeekCare]: {
            component: ComponentType.GooglePlace,
            errorMessages: {
                pattern: "general.form.error.required"
            },
            componentMetadata: {
                placeholder: "general.search",
                showActionClearButton: false,
                placesRequest: {
                    fields: ["name", "formatted_address"]
                },
                autocompletionRequest: {
                    types: [PlaceTypes.Establishment]
                }
            }
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.SearchableWhichDentistVisited]: {
            component: ComponentType.GooglePlace,
            errorMessages: {
                pattern: "general.form.error.required"
            },
            componentMetadata: {
                placeholder: "general.search",
                showActionClearButton: false,
                placesRequest: {
                    fields: ["name", "formatted_address"]
                },
                autocompletionRequest: {
                    types: [PlaceTypes.Establishment]
                }
            }
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.TextAreaDescribeTheAccident]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerWhenDidYouLeaveHomeQuestionId]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerPlannedReturnDateQuestionId]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any,
            validation: {
                function: ValidationFunctionNameList.validateStartDateEndDate,
                params: {
                    fields: {
                        startDate: pathof(AccidentDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerWhenDidYouLeaveHomeLineId}`).value),
                        endDate: pathof(AccidentDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerPlannedReturnDateLineId}`).value)
                    },
                    errors: {
                        startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `aboutAccident.${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerPlannedReturnDateLineId}.error.startDateGreaterThanEndDate`)
                    }
                }
            } as any

        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerActualReturnDateQuestionId]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any,
            validation: {
                function: ValidationFunctionNameList.validateStartDateEndDate,
                params: {
                    fields: {
                        startDate: pathof(AccidentDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerWhenDidYouLeaveHomeLineId}`).value),
                        endDate: pathof(AccidentDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerActualReturnDateQuestionLineId}`).value)
                    },
                    errors: {
                        startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `aboutAccident.${Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerActualReturnDateQuestionLineId}.error.startDateGreaterThanEndDate`)
                    }
                }
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerWhenDidYouSeekCare]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DatePickerWhenDidYouVisitDentist]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.CostForTravelForInjuredPerson]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DentalCareAmount]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.NumberInputNumberOfDaysRest]: {
            component: ComponentType.NumberInput,
            default: 1,
            errorMessages: {
                type: "general.form.error.required",
                required: "general.form.error.required"
            },
            componentMetadata: {
                min: 1,
                max: 200
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.AccidentDuringTravel.DropDownSearchableLossEventCountry]: {
            component: ComponentType.DropDown,
            componentMetadata: {
                isSearchable: true
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.DatePickerMedicalCare]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.DatePickerPlannedDeparture]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.DatePickerDeparture]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.DatePickerPlannedReturn]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.DatePickerReturn]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder",
                maxDate: moment().toISOString()
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.TextNumericCardNumber]: {
            component: ComponentType.TextBox,
            minLength: 15,
            maxLength: 16,
            pattern: REGEXP.POSITIVE_NUMBER,
            errorMessages: {
                required: "general.form.error.required",
                minLength: "general.form.error.minLength15Chars",
                maxLength: "general.form.error.maxLength16Chars",
                pattern: "general.form.error.pattern.numeric"
            },
            componentMetadata: {
                type: InputType.number
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.HowManyPersonsAreAffectedByTheEvent]: {
            component: ComponentType.DropDown
        },
        [Constants.Questionnaire.QuestionTypes.InstalledHeater.TextAreaDescribeTheError1100646]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber1]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber1}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber2]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber2}`).contact.ssn)

        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber3]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber3}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber4]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber4}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber5]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber5}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber6]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber6}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber7]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber7}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber8]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber8}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber9]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber9}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.CancelledTravel.EnterPersonalNumber10]: {
            component: ComponentType.PersonSsnNumberContainerSingle,
            title: "claim.cancelledTravel.questionnaire.enterPersonalNumber.title",
            onChange: CallbackNameList.handleProtectedContact,
            componentMetadata: cancelledTravelEnterPersonalNumberMetadata,
            validation: setCustomValidationForSSN(ClaimJourneyModel.questionnairePCOL.prop(`_${Constants.Questionnaire.AnswerLineIds.CancelledTravel.EnterPersonalNumber10}`).contact.ssn)
        },
        [Constants.Questionnaire.QuestionTypes.InstalledHeater.DatePickerInstallationYear1100619]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearFormat,
                maxDetail: "decade",
                minDetail: "decade",
                inputMode: InputMode.numeric
            } as any
        },
        [`${Constants.Questionnaire.QuestionTypes.InstalledHeater.CostOfRepair1100625}`]: {
            component: ComponentType.CurrencyInputField,
            minLength: 1,
            maxLength: 9,
            errorMessages: {
                pattern: "general.form.error.pattern.numeric.positive"
            },
            componentMetadata: {
                suffix: "general.price.suffix"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DropdownTypeOfTrip]: {
            component: ComponentType.DropDown,
            onChange: CallbackNameList.checkIllnessDuringTravelIditSkipAboutIllnessStep
        },
        [`${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DropDownMultiSelectIllnessConsequencesBusinessTrip}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DropDownMultiSelectDentalProblemConsequences}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DropDownMultiSelectDentalProblemConsequencesBusinessTrip}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.TextAreaWhatHasHappened}`]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.GooglePlacesCareSoughtLocation]: {
            component: ComponentType.GooglePlace,
            errorMessages: {
                pattern: "general.form.error.required"
            },
            componentMetadata: {
                placeholder: "general.search",
                showActionClearButton: false,
                placesRequest: {
                    fields: ["name", "formatted_address"]
                },
                autocompletionRequest: {
                    types: [PlaceTypes.Establishment]
                }
            }
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.TextAreaTypeOfTreatment]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.NumberInputNumberOfRestDays]: {
            component: ComponentType.NumberInput,
            default: 1,
            errorMessages: {
                type: "general.form.error.required"
            },
            componentMetadata: {
                min: 1,
                max: 200
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DatePickerPlannedReturnDate]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any,
            validation: {
                function: ValidationFunctionNameList.validateStartDateEndDate,
                params: {
                    fields: {
                        startDate: pathof(IllnessDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DepartureDateLineId}`).value),
                        endDate: pathof(IllnessDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.PlannedReturnDateLineId}`).value)
                    },
                    errors: {
                        startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `aboutEvent.${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.PlannedReturnDateLineId}.error.startDateGreaterThanEndDate`)
                    }
                }
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DatePickerActualReturnDate]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any,
            validation: {
                function: ValidationFunctionNameList.validateStartDateEndDate,
                params: {
                    fields: {
                        startDate: pathof(IllnessDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DepartureDateLineId}`).value),
                        endDate: pathof(IllnessDuringTravelJourneyIditModel.questionnairePCOL.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.ActualReturnDateLineId}`).value)
                    },
                    errors: {
                        startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `aboutEvent.${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.ActualReturnDateLineId}.error.startDateGreaterThanEndDate`)
                    }
                }
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.DatePickerHospitalizationDischargeDate]: {
            component: ComponentType.Date,
            errorMessages: {
                pattern: "general.form.error.dayMonthYear.invalidDate"
            },
            componentMetadata: {
                format: yearMonthDayFormat,
                placeholder: "general.form.date.mmddyyyy.placeholder"
            } as any,
            validation: {
                function: ValidationFunctionNameList.validateStartDateEndDate,
                params: {
                    fields: {
                        startDate: pathof(IllnessDuringTravelJourneyIditModel.damage.hospitalization.at(index || 0).questionnaireDamagedItems.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.HospitalizationAdmissionDateLineId}`).value),
                        endDate: pathof(IllnessDuringTravelJourneyIditModel.damage.hospitalization.at(index || 0).questionnaireDamagedItems.prop(`_${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.HospitalizationDischargeDateLineId}`).value)
                    },
                    errors: {
                        startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `aboutIllness.${Constants.Questionnaire.QuestionTypes.IllnessDuringTravel.HospitalizationDischargeDateLineId}.error.startDateGreaterThanEndDate`)
                    }
                }
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Fire.TextAreaDescribeTheEvent]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [Constants.Questionnaire.QuestionTypes.Fire.TextAreaDescribeDamages]: {
            component: ComponentType.Textarea,
            maxLength: 300,
            errorMessages: {
                pattern: "general.form.error.maxLength300Chars"
            },
            componentMetadata: {
                lineCount: 3,
                placeholder: "general.claim.damage.event.description.placeholder"
            } as any
        },
        [`${Constants.Questionnaire.QuestionTypes.Fire.DropDownMultiSelectWhatDamaged1100702}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.Fire.DropDownMultiSelectWhatDamaged1100704}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.Fire.DropDownMultiSelectWhatDamaged1100706}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.Fire.DropDownMultiSelectWhatDamaged1100708}`]: {
            component: ComponentType.DropDownMultiSelect
        },
        [`${Constants.Questionnaire.QuestionTypes.Fire.DropDownMultiSelectWhatDamaged1100710}`]: {
            component: ComponentType.DropDownMultiSelect
        },
    } as IComponentMapping;
};

export const defaultComponentMapping = (question: Questionnaire): DeepPartial<Field> => {
    // override based on question.answerDefinition.answerValues length
    if (question.questionId && question?.answerDefinition?.answerValues) {
        if (question?.answerDefinition?.answerValues.length > 10) {
            return ({
                component: ComponentType.DropDown,
                componentMetadata: {
                    isSearchable: true
                } as any
            });
        }
        if (question?.answerDefinition?.answerValues.length > 2) {
            return ({
                component: ComponentType.DropDown
            });
        }
    }

    // default textbox or toggle button mapping
    if (question.answerDefinition && question.answerDefinition.inputTypeId) {
        return inputTypeMapping[question.answerDefinition.inputTypeId!];
    }

    return {} as DeepPartial<Field>;
};


export const answerHeaderMapping = (cmsParams: ICmsParams): IComponentMapping => ({
    [Constants.Questionnaire.AnswerHeader.DropDownSearchable]: {
        component: ComponentType.DropDown,
        componentMetadata: {
            isSearchable: true
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DropDownMultiSelect]: {
        component: ComponentType.DropDownMultiSelect,
        componentMetadata: {
            help: "general.claim.questionnaire.additionalItems.helpText"
        }
    },
    [Constants.Questionnaire.AnswerHeader.DropDownMultiSelect1100078]: {
        component: ComponentType.DropDownMultiSelect,
        componentMetadata: {
            help: "general.claim.questionnaire.additionalItems.helpText"
        }
    },
    [Constants.Questionnaire.AnswerHeader.DropDownMultiSelect1100077]: {
        component: ComponentType.DropDownMultiSelect,
        componentMetadata: {
            help: "general.claim.questionnaire.additionalItems.helpText"
        }
    },
    [Constants.Questionnaire.AnswerHeader.DropDownMultiSelectMobilePhone]: {
        component: ComponentType.DropDownMultiSelect
    },
    [`${Constants.Questionnaire.AnswerHeader.NumericTextBox}`]: {
        component: ComponentType.CurrencyInputField,
        minLength: 1,
        maxLength: 9,
        errorMessages: {
            pattern: "general.form.error.pattern.numeric.positive"
        },
        componentMetadata: {
            suffix: "general.price.suffix"
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DatePickerLong]: {
        component: ComponentType.Date,
        errorMessages: {
            pattern: "general.form.error.dayMonthYear.invalidDate"
        },
        componentMetadata: {
            format: yearMonthDayFormat,
            placeholder: "general.form.date.mmddyyyy.placeholder",
            maxDate: moment().toISOString()
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.TimePicker]: {
        component: ComponentType.TextBox,
        errorMessages: {
            pattern: "general.form.error.hhmm.invalidTime"
        },
        componentMetadata: {
            placeholder: "general.form.time.hhmm.placeholder"
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DropDownConsequencesOfTheInjury]: {
        component: ComponentType.DropDownMultiSelect
    },
    [Constants.Questionnaire.AnswerHeader.DropDownConsequencesOfTheTeethInjury]: {
        component: ComponentType.DropDownMultiSelect
    }
});
