import {IMessages} from "../../cms";
import {ISessionContextManager, IStorage, StorageKeys} from "../../services";
import {container} from "../../inversify.config";
import {Types} from "../../Types";
import {IUserClient, UrlHelper} from "@folksam-digital/services";
import moment from "moment";
import {ClaimMapping} from "./ClaimAnalytics";
import {ProductMapping} from "./ProductAnalytics";

export enum SatelliteEvents {
    Start = "start",
    Step = "step",
    Complete = "complete",
    Initiated = "initiated",
    ContactSubmit = "contact_submit"
}

export enum NewSatelliteEvents {
    Start = "Start",
    Step = "Step",
    Complete = "Complete",
}

export enum PageTypes {
    SPAPage = "SPA_page"
}

export enum SatelliteEventTransactionTypes {
    TransactionPage = "transaction_page",
    Service = "service",
    Lead = "lead",
    ClaimFlow = "claim_flow",
    Application = "application",
}

export enum NewSatelliteEventTransactionTypes {
    TransactionPage = "transaction",
    Service = "service",
    Lead = "lead",
    ClaimFlow = "claim",
    Application = "application",
}

export enum ApplicationStatus {
    Success = "success",
}

export interface BaseMapping {
    pageName: string;
    pageUrl?: string;
    pageType: string;
    siteSection1: string;
    siteSection2: string;
    siteSection3: string;
    siteSection4: string;
    customerId?: string;
    age?: string;
    gender?: string;
    serviceName?: string;
    errorMessage?: string;
    ref?: string;
}

export interface NoAddtionalMapping {}

export interface IOnTriggerDataLayerEventParams {
    messages: IMessages;
    errorMessage?: string;
    currentStep: string;
    currentStepIndex?: number;
    transactionType: SatelliteEventTransactionTypes;
    event: SatelliteEvents;
    data?: any;
    journeyId?: string;
    transactionId?: string;
    dataChanged?: boolean;
    applicationStatus?: ApplicationStatus;
}

export type TOnTriggerDataLayerEventFunction = (params: IOnTriggerDataLayerEventParams) => void;

export interface IOnTriggerDataLayerEvent {
    onTriggerDataLayerEvent: TOnTriggerDataLayerEventFunction
}

export enum JourneyType {
    Claim = "claim",
    Product = "product",
    SelfService = "selfService",
}

type AdditionalMapping = BaseMapping | ClaimMapping | ProductMapping | NoAddtionalMapping

export abstract class BaseAnalytics implements IOnTriggerDataLayerEvent {
    private readonly sessionContextManager: ISessionContextManager;
    private readonly userClient: IUserClient;
    protected readonly sessionStorage: IStorage;

    public abstract onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void>;

    constructor() {
        this.sessionContextManager = container.get<ISessionContextManager>(Types.SessionContextManager);
        this.userClient = container.get<IUserClient>(Types.UserClient);
        this.sessionStorage = container.get<IStorage>(Types.SessionStorage);
    }

    protected async setDataLayer(additionalMappingObject: AdditionalMapping, key: string, params: IOnTriggerDataLayerEventParams = {} as any, journeyType: JourneyType) {
        // If flag already set and user is on success page return
        if (this.analyticsFlowCompleted && this.isFlowCompleted(key)) {
            return;
        }
        // Reset the flag when user not on success page anymore
        if (!this.isFlowCompleted(key) && this.analyticsFlowCompleted) {
            this.sessionStorage.set(StorageKeys.ANALYTICS_FLOW_COMPLETED, false);
        }

        const sessionParams = this.sessionStorage.get("params") as any;
        const journeyName: string = params.messages[`general.adobe.${journeyType}.${params.journeyId!.toString()}`];
        const pageStart = params.messages[`general.adobe.application`];
        const pageType =  params.messages[`general.adobe.${journeyType}.pageType`];

        const stepName: string = this.getStepName(params);

        const defaultTracking : BaseMapping = {
            pageName: `${pageStart}|${pageType}|${journeyName}|${stepName}`,
            pageType: `${pageType}`,
            pageUrl: this.getPageUrl(journeyType, params),
            siteSection1: `${pageStart}`,
            siteSection2: `${pageStart}|${pageType}`,
            siteSection3: `${pageStart}|${pageType}|${journeyName}`,
            siteSection4: `${pageStart}|${pageType}|${journeyName}|${stepName}`,
        }

        if (params.data?.contact?.externalContactNumber) {
            defaultTracking.customerId = await this.getUserId(params.data?.contact?.externalContactNumber)
        }

        if (params.data?.contact?.dateOfBirth) {
            defaultTracking.age = (params.data?.contact?.dateOfBirth && moment.utc(params.data?.contact?.dateOfBirth)?.year()?.toString()) || "";
        }

        if (params.data?.contact?.gender) {
            defaultTracking.gender = params.data?.contact?.gender ? params.messages[`general.adobe.gender.${params.data.contact.gender}`] : "";
        }

        if (sessionParams.persistentOriginReferer && this.getOriginReferer(sessionParams.persistentOriginReferer, params)) {
            defaultTracking.ref = this.getOriginReferer(sessionParams.persistentOriginReferer, params);
        }

        if (this.isStepTypeError(params.currentStep)){
            defaultTracking.errorMessage = params.errorMessage;
            key = "errorPage";
        }

        const trackingObj = {...defaultTracking, ...additionalMappingObject}

        window.datalayer = Object.assign(window.datalayer, trackingObj);

        if (window._satellite && window._satellite.track) {
            window._satellite.track(key);
            // Set flag when triggered on success page to prevent repeated triggers on reload
            if (this.isFlowCompleted(key)) {
                this.sessionStorage.set(StorageKeys.ANALYTICS_FLOW_COMPLETED, true);
            }
        }
    }

    private getPageUrl(journeyType: JourneyType, params: IOnTriggerDataLayerEventParams): string {
        switch(journeyType){
            case JourneyType.Claim:
                return UrlHelper.getClaimAnalyticsPageURL(params?.journeyId!, window.origin, params?.currentStep);
            case JourneyType.Product:
            case JourneyType.SelfService:
            default:
                return UrlHelper.getPurchaseAnalyticsPageURL(params?.journeyId!, window.origin, params?.currentStep);
        }
    }

    protected getStepName(params: IOnTriggerDataLayerEventParams): string {
        if (this.isStepTypeError(params.currentStep)) {
            return params.messages["general.adobe.error"];
        }

        if (this.isStepTypeSuccess(params.currentStep)) {
            return params.messages["general.adobe.success"];
        }

        return params.messages[`general.adobe.step.${params.currentStep}`];
    }

    protected checkMatch(value: string, compareKey: string): string | undefined {
        return (value === compareKey) ? compareKey : undefined;
    }

    protected getTransactionId(): string {
        const context = this.sessionContextManager.getSessionContext();
        return context && context.transactionId ? context.transactionId : "";
    }

    protected async getUserId(input?: string): Promise<string> {
        let userId = "";
        try {
            userId = await this.loadUserId(input);
        } catch (e) {
            console.warn("Could not load user id");
        }

        return userId;
    }

    protected isStepTypeError(stepType: string): boolean {
        return stepType === "error";
    }

    protected isStepTypeSuccess(stepType: string): boolean {
        return stepType === "success";
    }

    protected getPageName(params: IOnTriggerDataLayerEventParams): string {
        let pageName: string = "";
        if (params.currentStepIndex) {
            pageName = params.messages["general.adobe.step"];
        }
        if (params.currentStepIndex === undefined) {
            pageName = "|" + params.messages[`general.adobe.${params.currentStep}`];
        }

        if (this.isStepTypeError(params.currentStep)) {
            pageName = "|" + params.messages["general.adobe.error"];
        }
        return pageName;
    }

    protected getTrackingKey(key: string): string
    {
        const translatedKey = key
            .replace(SatelliteEvents.Start, NewSatelliteEvents.Start)
            .replace(SatelliteEvents.Step, NewSatelliteEvents.Step)
            .replace(SatelliteEvents.Complete, NewSatelliteEvents.Complete)
            .replace(SatelliteEventTransactionTypes.ClaimFlow, NewSatelliteEventTransactionTypes.ClaimFlow)
            .replace(SatelliteEventTransactionTypes.TransactionPage, NewSatelliteEventTransactionTypes.TransactionPage)

        return translatedKey;
    }

    private async loadUserId(input?: string): Promise<string | never> {
        if (window.datalayer.hasOwnProperty("customerId")) {
            return window.datalayer["customerId"];
        } else if (input) {
            return await this.userClient.getHash(input);
        }

        return "";
    }

    private get analyticsFlowCompleted(): boolean | undefined {
        return this.sessionStorage.get<boolean>(StorageKeys.ANALYTICS_FLOW_COMPLETED, false);
    }

    private isFlowCompleted(key: string): boolean {
        switch (key) {
            case `${SatelliteEventTransactionTypes.ClaimFlow}_${SatelliteEvents.Complete}`:
                return true;
            case `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Complete}`:
                return true;
            default:
                return false;
        }
    }

    private getOriginReferer(origin: string, params: IOnTriggerDataLayerEventParams): string|undefined {
        try{
            const host = new URL(origin).host;

            switch (host) {
                case "folksam.se":
                case "atst.folksam.se":
                case "stst.folksam.se":
                    return params.messages["general.adobe.openPages"];
                case "secure.folksam.se":
                case "secure.atst.folksam.se":
                case "secure.stst.folksam.se":
                    return params.messages["general.adobe.myPages"];
                default:
                    return undefined;
            }
        }
        catch(error){
            throw new Error(`Could not get origin referer. ${error}`);
        }
    }
}
