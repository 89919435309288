import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {B, ButtonResponsive, CTACardResponsive, Headings, Spacing} from "@folksam-digital/ui";
import {FormContext} from "../../../../components/journey/form/FormContext";

export interface IBannerWithLinkProps extends WrappedComponentProps {
    titleId?: string;
    textId?: string;
    linkId?: string;
    buttonTextId?: string;
}

export class BannerWithLinkComponent extends React.Component<IBannerWithLinkProps, {}> {
    public static contextType = FormContext;

    public render(): React.ReactNode {
        const {intl, titleId, textId, linkId, buttonTextId} = this.props;

        return (
            <CTACardResponsive themeColor={"secondarySuccess1"} rounded>
                <Headings.H3 color={"secondary1"}>
                    <FormattedMessage id={titleId}/>
                </Headings.H3>
                <B><FormattedMessage id={textId}/></B>
                <Spacing top={6}/>
                <ButtonResponsive
                    primary
                    onClick={() => window.open(intl.formatMessage({id:linkId}))}
                    type='button'
                    full
                >
                    <FormattedMessage
                        id={buttonTextId}/>
                </ButtonResponsive>
            </CTACardResponsive>
        );
    }
}

const BannerWithLink = injectIntl(BannerWithLinkComponent);
export {BannerWithLink};
