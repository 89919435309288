import {injectIntl} from "react-intl";
import {FormContext} from "../../../../components/journey/form/FormContext";
import {BannerWithLinkComponent} from "./BannerWithLink";
import {Constants} from "@folksam-digital/model";

class CoopBannerWithLinkComponent extends BannerWithLinkComponent {
    public static contextType = FormContext;
    public static defaultProps = {
        titleId: "general.success.coop.bannerWithLink.title",
        textId: "general.success.coop.bannerWithLink.text",
        linkId: Constants.Links.folksamCoopBannerLink,
        buttonTextId: "general.success.coop.bannerWithLink.buttonText"
    }
}

const CoopBannerWithLink = injectIntl(CoopBannerWithLinkComponent);
export {CoopBannerWithLink};