import {
    BaseAnalytics,
    IOnTriggerDataLayerEventParams,
    JourneyType,
    SatelliteEvents
} from "./BaseAnalytics";
import {
    ClaimBase,
    Constants,
    DelayedLuggage,
    PersonalAccident,
} from "@folksam-digital/model/lib";
import {IMessages} from "../../cms";
import isEmpty from "lodash/isEmpty";

export interface ClaimMapping {
    claimProcess: string;
    claimProduct: string;
    claimStartOption?: string;
    claimType: string;
    claimId?: string;
}

export class ClaimAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName: string = params.messages[`general.adobe.claim.${params.journeyId!.toString()}`];
        const isInformant: boolean | undefined =  params.data?.claimant?.isInformant;
        const damageTypes: { [key: string]: string[] } = this.getClaimDamageTypes(params);

        let dataLayerObj: ClaimMapping = await this.getDefaultClaimMapping(journeyName, params);

        if (isInformant !== undefined) {
            dataLayerObj.claimStartOption = isInformant ? params.messages["general.adobe.claim.startOption.self"] : params.messages["general.adobe.claim.startOption.other"];
        }

        if (params.data?.claimNumber){
            dataLayerObj.claimId = params.data?.claimNumber;
        }

        if (!isEmpty(damageTypes)) {
            dataLayerObj = {
                ...dataLayerObj,
                ...damageTypes
            };
        }

        let trackKey = this.getTrackingKey(`${params.transactionType}${params.event}`);

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}${nextStepIndex}`;
        }

        await this.setDataLayer(dataLayerObj, trackKey, params, JourneyType.Claim);
    }

    private async getDefaultClaimMapping(journeyName: string, params: IOnTriggerDataLayerEventParams): Promise<ClaimMapping> {
        return {
            claimProcess: this.getClaimProcessType(params.journeyId!, params.messages, journeyName),
            claimProduct: this.getClaimProductType(params.journeyId!, params.messages),
        } as ClaimMapping;
    }

    private getClaimDamageTypes(params: IOnTriggerDataLayerEventParams): { [key: string]: string[] } {
        switch (params.journeyId) {
            case Constants.Journey.PersonalAccident.Id:
                return this.getPersonalAccidentDamageTypes(params.data, params.messages, params.journeyId);
            case Constants.Journey.DelayedLuggage.Id:
                return this.getDelayedLuggageDamageTypes(params.data, params.messages, params.journeyId);
            default:
                return {};
        }
    }

    private getPersonalAccidentDamageTypes(data: ClaimBase<PersonalAccident>, messages: IMessages, journeyId: string): { [key: string]: string[] } {
        const damageTypes: { [key: string]: string[] } = {
            claimType: []
        };
        if (data?.damage?.isBodilyInjury) {
            damageTypes["claimType"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.Bodily}`]);
        }
        if (data?.damage?.isDentalInjury) {
            damageTypes["claimType"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.Dental}`]);
        }
        return damageTypes["claimType"].length > 0 ? damageTypes : {};
    }

    private getDelayedLuggageDamageTypes(data: ClaimBase<DelayedLuggage>, messages: IMessages, journeyId: string): { [key: string]: string[] } {
        const damageTypes: { [key: string]: string[] } = {
            claimType: []
        };

        if (data?.causeOfLoss) {
            damageTypes["claimType"].push(messages[`general.adobe.claim.${journeyId}.${data?.causeOfLoss}`]);
        }

        return damageTypes["claimType"].length > 0 ? damageTypes : {};
    }

    private getClaimProductType(journeyId: string, messages: IMessages): string {
        switch (journeyId) {
            case Constants.Journey.Burglary.Id:
            case Constants.Journey.BurglaryInVacationHome.Id:
            case Constants.Journey.Fire.Id:
            case Constants.Journey.Glass.Id:
            case Constants.Journey.GlassInVacationHome.Id:
            case Constants.Journey.FireInVacationHome.Id:
            case Constants.Journey.Nature.Id:
            case Constants.Journey.NatureInVacationHome.Id:
            case Constants.Journey.OtherDamageOrLoss.Id:
            case Constants.Journey.Storm.Id:
            case Constants.Journey.StormInVacationHome.Id:
            case Constants.Journey.Theft.Id:
            case Constants.Journey.TheftInVacationHome.Id:
            case Constants.Journey.Water.Id:
            case Constants.Journey.WaterInVacationHome.Id:
            case Constants.Journey.HouseholdAppliances.Id:
            case Constants.Journey.HouseholdAppliancesInVacationHome.Id:
            case Constants.Journey.Bicycle.Id:
                return messages["general.adobe.claim.claimProduct.boende"];
            case Constants.Journey.AccidentDuringTravel.Id:
            case Constants.Journey.IllnessDuringTravel.Id:
            case Constants.Journey.CancelledTravel.Id:
            case Constants.Journey.DelayedTraveler.Id:
            case Constants.Journey.DelayedLuggage.Id:
                return messages["general.adobe.claim.claimProduct.rese"];
            case Constants.Journey.VehicleCollision.Id:
            case Constants.Journey.VehicleCollisionWithAnimal.Id:
            case Constants.Journey.VehicleSingleAccident.Id:
            case Constants.Journey.VehicleHitAndRunAccident.Id:
            case Constants.Journey.VehicleVandalism.Id:
            case Constants.Journey.VehicleTheftOrBurglary.Id:
            case Constants.Journey.VehicleFire.Id:
            case Constants.Journey.VehicleEngineDamage.Id:
            case Constants.Journey.VehicleInteriorDamage.Id:
            case Constants.Journey.VehicleLossOfKey.Id:
            case Constants.Journey.VehicleMisfuelling.Id:
                return messages["general.adobe.claim.claimProduct.motor"];
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                return messages["general.adobe.claim.claimProduct.animal"];
            default:
                return messages["general.adobe.claim.claimProduct.person"];
        }
    }

    private getClaimProcessType(journeyId: string, messages: IMessages, journeyName: string): string {
        switch (journeyId) {
            case Constants.Journey.AccidentDuringTravel.Id:
            case Constants.Journey.IllnessDuringTravel.Id:
            case Constants.Journey.CancelledTravel.Id:
            case Constants.Journey.DelayedTraveler.Id:
            case Constants.Journey.DelayedLuggage.Id:
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                return messages[`general.adobe.claim.claimProcess.${journeyId}`];
            case Constants.Journey.Fire.Id:
            case Constants.Journey.VehicleFire.Id:
                return `${this.getClaimProductType(journeyId, messages)}|${journeyName}`;
            default:
                return journeyName;
        }
    }
}
