import * as React from "react";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {
    Badge,
    CompactCompareListTable,
    defaultTheme as theme,
    DisplayIcon,
    Grid,
    IconCheckmark,
    IconWinner,
    ListGroup,
    QuestionSkeleton,
    withViewContext
} from "@folksam-digital/ui";
import {injectIntl} from "react-intl";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {
    Constants,
    ICollectivesInfo,
    ICollectivesInfoAgreementContent,
    ICompactCoverListTableContentItemConfig,
    LegacyCoverPlan,
    pathof
} from "@folksam-digital/model";
import journeyBaseModel from "@folksam-digital/model/lib/model/JourneyBaseModel";
import HomeWithSmallBoatJourneyModel from "@folksam-digital/model/lib/model/HomeWithSmallBoatJourneyModel";
import {FormContext, IFormContext} from "../FormContext";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {ListGroupItems} from "./index";
import {Markdown} from "../../../Markdown";
import {CompactCoverListTableIcons} from "./helpers/compactCoverListTableIconMap";
import {AnalyticsHelper} from "../../../../Helpers/analytic/AnalitycsHelper";
import {withCmsContextConsumer} from "../../../../cms/withCmsContextConsumer";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IPopOverConfig {
    isPopOverDisplayed: boolean;
    id?: string;
    label?: string;
}

interface INode {
    sortWeight: number;
    name: string;
    content?: string;
    [key: string]: string | boolean | undefined | number;
}

interface IMetadata {
    coversList?: string[];
    columnContentItemConfig?: ICompactCoverListTableContentItemConfig | ICompactCoverListTableContentItemConfig[];
    icon: string;
    disableCoverPlans?: string[];
    filteredCoverPlans?: string[]; // Useful for mocking more complex behaviour
    sourcePath?: string;
    selectedCoverPlanSourcePath?: string;
    disabledCoverPlansPath?: string;
}

interface IState {
    selected?: number;
    collectivesInfoContentFlat?: ICollectivesInfoAgreementContent[];
}

class CompactCoverListTableInternal extends InputComponentBase<string, IMetadata, IState, any> {
    public static contextType = FormContext;
    context!: any;

    constructor(props: any) {
        super(props);
        this.state = {
            selected: undefined
        };
    }

    getCoverPlansPath() {
        return this.metadata.sourcePath || pathof(journeyBaseModel.policy.premium.coverPlans);
    }

    getSelectedCoverPlanPath() {
        return this.metadata.selectedCoverPlanSourcePath || pathof(journeyBaseModel.policy.coverPlan);
    }

    public componentDidMount(): void {
        this.setSelectedCoverPlanIndex();
        if (this.getItemConfigList()?.some(itemConfig => !!itemConfig?.rowIdToAppend)) {
            this.setState({collectivesInfoContentFlat: this.getCollectivesInfoScenariosContentFlat()});
        }
    }

    private getCollectivesInfoScenariosContentFlat(): ICollectivesInfoAgreementContent[] | undefined {
        if (this.context?.data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome) {
            const collectivesInfoList = get(this.context?.data, pathof(HomeWithSmallBoatJourneyModel.policy.insuredObject.group.collectivesInfo)) || [] as ICollectivesInfo[];

            if (!isEmpty(collectivesInfoList)) {
                let collectivesInfoConcatResult = [] as ICollectivesInfoAgreementContent[];

                for (const collectivesInfoObj of collectivesInfoList) {
                    const {alreadySelectedAddonsMandatory} = collectivesInfoObj;
                    if (Array.isArray(alreadySelectedAddonsMandatory)) {
                        collectivesInfoConcatResult = collectivesInfoConcatResult.concat(alreadySelectedAddonsMandatory)
                    }
                    const {alreadySelectedAddonsSubscription} = collectivesInfoObj;
                    if (Array.isArray(alreadySelectedAddonsSubscription)) {
                        collectivesInfoConcatResult = collectivesInfoConcatResult.concat(alreadySelectedAddonsSubscription)
                    }
                }
                return collectivesInfoConcatResult;
            }
        }
    }

    private setSelectedCoverPlanIndex() {
        const sourcePath = this.getCoverPlansPath();
        const selectedCoverPlanPath = this.getSelectedCoverPlanPath();
        const coverPlans = get(this.context?.data, sourcePath, []);
        const selectedCoverPlan = get(this.context?.data, selectedCoverPlanPath, undefined);

        if (selectedCoverPlan) {
            const selected = coverPlans.findIndex((row: LegacyCoverPlan) => row.id === selectedCoverPlan);
            this.setState({selected});
        }
    }

    public componentDidUpdate(prevProps: Readonly<IInputComponentProps<string, IMetadata>>, prevState: Readonly<{ selected: number }>, snapshot?: any) {
        this.setSelectedCoverPlanIndex();
    }

    public onCoverSelectionClick = (event: any, value: any) => {
        this.setState({selected: value});
    };

    public render() {
        const {coversList, icon, filteredCoverPlans} = this.metadata;
        const {viewContext} = this.props;
        const {disableButton} = this.context;
        const sourcePath = this.getCoverPlansPath();
        let coverPlans: LegacyCoverPlan[] = Object.values(get(this.context.data, sourcePath, {}) || {});
        if (!!filteredCoverPlans && !isEmpty(filteredCoverPlans)) {
            coverPlans = coverPlans.filter((coverPlan: LegacyCoverPlan) => !filteredCoverPlans.includes(coverPlan?.id!));
        }

        const isMobile = viewContext.isMobile;
        const popOverConfig = this.getPopOverConfig(coverPlans);

        return (
                    <FormContext.Consumer>
                        {(context: IFormContext) => (
                            context.data.policy?.premium && coverPlans ?
                                <FormFieldLayout {...this.getLayoutProps()}>
                                    <Grid style={{paddingTop: popOverConfig.isPopOverDisplayed ? 31 : 17}}>
                                        <Grid.Row style={{flexDirection: "column", marginLeft: 0, marginRight: 0, gap: theme.margin['5']}}>
                                            <ListGroup key={"sm"} horizontal={"xs"}>
                                                <ListGroupItems
                                                    isUpdating={disableButton}
                                                    spacing={isMobile ? "md" : "lg"}
                                                    items={this.getRenders(coverPlans, popOverConfig)}
                                                    selected={this.state.selected}
                                                    icon={CompactCoverListTableIcons[icon]}
                                                    selectHandler={(event: any, value: number) => {
                                                        event.preventDefault();
                                                        if (!disableButton && this.state.selected !== value) {
                                                            this.onChangeWithValidation(coverPlans[value].id);
                                                            this.onCoverSelectionClick(event, value);
                                                        }
                                                    }}
                                                />
                                            </ListGroup>
                                            <CompactCompareListTable
                                                style={{fontSize: theme.textSizes.base}}
                                                selected={this.state.selected}
                                                columns={this.getColumns(coverPlans, isMobile)}
                                                data={this.getNodes(coversList, coverPlans)}
                                            />
                                        </Grid.Row>
                                    </Grid>
                                </FormFieldLayout>
                                :
                                <QuestionSkeleton/>
                        )}
                    </FormContext.Consumer>
        );
    }

    private getConfigFieldValue(data: any, config?: ICompactCoverListTableContentItemConfig): any | undefined {
        if (config?.condition && config.condition?.fieldPath && data) {
            return get(data, config.condition.fieldPath);
        }
    }

    private getItemConfig(): ICompactCoverListTableContentItemConfig | undefined {
        const {columnContentItemConfig} = this.metadata;
        if (columnContentItemConfig && !Array.isArray(columnContentItemConfig)) {
            return columnContentItemConfig;
        }
    }

    private getItemConfigList(): ICompactCoverListTableContentItemConfig[] | undefined {
        const {columnContentItemConfig} = this.metadata;
        if (columnContentItemConfig && Array.isArray(columnContentItemConfig)) {
            return columnContentItemConfig;
        }
    }

    private createNode(cover: string, coverPlanIds: string[]): INode {
        const singleCover = this.props.intl.formatMessage({id: cover}).split("__");
        const coverName = singleCover[0];
        const coverDescription = singleCover[1];
        let includedIn = singleCover[2];
        const nodeObj: any = {
            sortWeight: 0,
            listId: cover,
            name: coverName,
            content: coverDescription ? <Markdown source={coverDescription}/> : undefined,
        } as INode;
        if (includedIn) {
            includedIn = includedIn.split("|");
            includedIn.forEach((coverPlan: string) => {
                    nodeObj[coverPlan] = true;
                    if (coverPlanIds.includes(coverPlan)) {
                        nodeObj.sortWeight += 1;
                    }
                }
            );
        }

        return nodeObj;
    }

    private getNodes = (coversList: string[] | undefined, coverPlans: any): INode[] | undefined => {
        if (!coversList) {
            return;
        }

        const coverPlanIds = coverPlans.map((coverPlan: any) => coverPlan.id);
        let coversListTmp = coversList.map(cover => {
            return this.createNode(cover, coverPlanIds);
        });

        // // @ToDo - can be removed after 5th may. edge case in cat step2 of hiding node, based on criteria
        if (this.context?.data?.journeyId === Constants.Journey.Cat.Id) {
            const columnContentItemConfig = this.getItemConfig();
            const fieldPathValue = this.getConfigFieldValue(this.context?.data, columnContentItemConfig);
            if (fieldPathValue?.length <= 2) {
                coversListTmp = coversListTmp.filter(listObj => listObj?.listId !== columnContentItemConfig?.rowId);
            }
        }

        // scenario - show collectivesInfo covers
        if (this.state?.collectivesInfoContentFlat && !isEmpty(this.state.collectivesInfoContentFlat)) {
            const columnContentItemConfigList = this.getItemConfigList();
            for (const config of columnContentItemConfigList || []) {
                const {rowIdToAppend} = config;
                const {rowId, dataTarget} = rowIdToAppend || {};

                if (rowId && dataTarget?.keyName && dataTarget?.id && this.state.collectivesInfoContentFlat.find(cco => cco[dataTarget.keyName as keyof ICollectivesInfoAgreementContent] === dataTarget.id)) {
                    coversListTmp.push(this.createNode(rowId, coverPlanIds));
                }
            }
        }

        // scenario - show "Travel Ready" addon as included;
        /* !!!Keep this block last or so that it's after "collectivesInfo covers" append scenario as both, based on CMS will have sortWeight(3),
         but rowIdToAppend.rowId here displayed V icons will result based on coverPlans data included flag. */
        if (this.context?.data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome) {
            const columnContentItemConfigList = this.getItemConfigList();
            const travelAddonIds = [Constants.Cover.HomeIditAddons.TravelInsurance, Constants.Cover.HomeIditAddons.TravelInsuranceStudentUnion];
            for (const config of columnContentItemConfigList || []) {
                const {rowIdToAppend} = config;
                const fieldPathValue = this.getConfigFieldValue(this.context?.data, config);
                // append row if addon is included, see PCBCP mapping
                if (rowIdToAppend?.rowId && fieldPathValue && fieldPathValue?.included && travelAddonIds.includes(fieldPathValue?.id)) {
                    coversListTmp.push(this.createNode(rowIdToAppend.rowId, coverPlanIds));
                }
            }
        }

        return coversListTmp.sort((a, b) => b.sortWeight - a.sortWeight);
    };

    private getColumnContent(coverPlan: any, index: number, item: any) {
        const columnContentItemConfig = this.getItemConfig();
        const columnContentItemConfigList = this.getItemConfigList();
        const styleBase = {fontSize: theme.textSizes.xs, fontWeight: theme.fontWeights.regular};
        const defaultIcon = (<IconCheckmark style={{
            width: 20,
            height: 13,
            fontWeight: theme.fontWeights.medium,
            color: index === this.state.selected ? theme.colors.primary2 : theme.colors.senary1
        }}/>);

        if (item.listId === columnContentItemConfig?.rowId && item[coverPlan.id] && columnContentItemConfig?.messageId) {
            // config process logic - rowId + messageId + condition => messageId overwrites IconCheckmark;
            const fieldPathValue = this.getConfigFieldValue(this.context?.data, columnContentItemConfig);

            if (fieldPathValue && fieldPathValue === columnContentItemConfig?.condition?.compareValue) {
                return <div style={styleBase}>
                    {this.props.intl.formatMessage({id: columnContentItemConfig.messageId})}
                </div>
            }
        } else if (columnContentItemConfigList && item[coverPlan.id]) {
            // config process logic - rowId + colId + messageId => messageId overwrites IconCheckmark;
            const configRowIdColId = columnContentItemConfigList.find((itemConfig) => itemConfig.rowId === item.listId && itemConfig.colId === coverPlan.id);
            if (configRowIdColId && configRowIdColId?.messageId) {
                return <div style={{textAlign: "center", ...styleBase}}>
                    <Markdown source={this.props.intl.formatMessage({id: configRowIdColId.messageId})}/>
                </div>
            }

            // scenario - show "Travel Ready" addon as included - check under wich coverPlan addon is included.
            // config process logic - rowIdToAppend.rowId + condition => IconCheckmark is kept only where config and coverPlan data conditions evaluated
            const travelAddonIds = [Constants.Cover.HomeIditAddons.TravelInsurance, Constants.Cover.HomeIditAddons.TravelInsuranceStudentUnion];
            for (const config of columnContentItemConfigList || []) {
                const {rowIdToAppend} = config;
                const fieldPathValue = this.getConfigFieldValue(this.context?.data, config);
                if (rowIdToAppend?.rowId === item.listId && travelAddonIds.includes(fieldPathValue?.id)) {
                    const covers = coverPlan?.covers;
                    const bigCoverPlans = [
                        Constants.CoverPlan.HomeIdit.Big,
                        Constants.CoverPlan.HomeBuildingAdditionIdit.BigWithHomeBuildingBig,
                        Constants.CoverPlan.HomeBuildingAdditionIdit.BigWithHomeBuildingBasic
                    ];
                    const addonCover = covers && Array.isArray(covers) && covers.find(cover => cover?.id === fieldPathValue.id);
                    if (addonCover) {
                        return defaultIcon;
                    } else if (!addonCover && bigCoverPlans.includes(coverPlan.id)) {
                        // if row was appended for MLN, then show included also for big coverPlan;
                        // Currently this is workaround for PCBCP res big coverplan not having travelReady cover returned, but logically must be displayed as it's always included in idit configuration.
                        return defaultIcon;
                    }
                    return <></>;
                }
            }

        }

        if (item[coverPlan.id]) {
            return defaultIcon;
        }
        return <></>;
    }

    private getColumns(coverPlans: any, isMobile: boolean) {
        const columnProps = {centered: true, style: {color: theme.colors.senary1}};
        const prefix = CmsHelper.getPrefix(this.props.cmsContext);
        const columns = coverPlans.map((coverPlan: any, index: number) => {
            return {
                key: index,
                name: this.props.intl.formatMessage({id: `${prefix}.coverPlans.${coverPlan.id}.title`}),
                renderCell: (item: any, props: any) => (
                    <Grid.Col key={index} centered={true}
                              style={index === 0 ? {marginLeft: "auto"} : undefined} {...props}>
                        {this.getColumnContent(coverPlan, index, item)}
                    </Grid.Col>
                ),
                props: {
                    ...columnProps,
                    style: index === 0 ? {marginLeft: "auto", ...columnProps.style} : columnProps.style
                }
            };
        });

        return [
            {
                name: this.props.intl.formatMessage({id: "general.covers.whatIsIncluded.title"}),
                renderCell: (item: any, props: any) => (
                    <Grid.Col
                        vCentered={true}
                        role="button"
                        tabIndex={0}
                        style={{
                            color: theme.colors.primary2,
                            fontWeight: theme.fontWeights.medium,
                            maxWidth: isMobile ? "205px" : "280px",
                            padding: "8px 4px",
                            boxSizing: "border-box"
                        }} {...props}>
                        {item.name}
                    </Grid.Col>
                ),
                props: {vCentered: true, style: {color: "#363636", fontWeight: theme.fontWeights.semiBold}}
            },
            ...columns
        ];
    }

    private getRenders(coverPlans: LegacyCoverPlan[], popOverConfig: IPopOverConfig) {
        const {disableCoverPlans, disabledCoverPlansPath} = this.metadata;
        const {disableButton} = this.context;
        const prefix = CmsHelper.getPrefix(this.props.cmsContext);
        const path = disabledCoverPlansPath || pathof(journeyBaseModel.uiState?.disabledCoverPlanIds);
        const uiStateDisabledCoverPlans = get(this.context.data, path);

        return coverPlans.map((coverPlan: any) => {
            const isDisabled = (disableCoverPlans && disableCoverPlans.includes(coverPlan.id)) || uiStateDisabledCoverPlans?.includes(coverPlan.id);
            return {
                coverPlanId: coverPlan.id,
                iconText: this.props.intl.formatMessage({id: `${prefix}.coverPlans.${coverPlan.id}.title`}),
                currencyText: (
                    <>
                        <div style={{
                            fontSize: theme.textSizes.large,
                            fontFamily: theme.fonts.serif,
                            fontWeight: theme.fontWeights.semiBold
                        }}>
                            {isDisabled ? `-` : Math.round(coverPlan.monthlyPremium)} {isDisabled ? "" : this.props.intl.formatMessage({id: `general.listGroup.currencyText.suffix`})}
                        </div>
                        <div style={{
                            fontSize: theme.textSizes.base,
                            fontFamily: theme.fonts.serif,
                            fontWeight: theme.fontWeights.medium
                        }}>
                            {isDisabled ? this.props.intl.formatMessage({id: `${prefix}.coverPlans.unavailable.title`}) : this.props.intl.formatMessage({id: `general.listGroup.currencyText.frequency`})}
                        </div>
                    </>
                ),
                popover: popOverConfig.isPopOverDisplayed && popOverConfig.id === coverPlan.id && (
                    <Badge
                        bookmark={true}
                        type='offer'
                        icon={<DisplayIcon medium={true} icon={<IconWinner/>}/>}
                        text={popOverConfig.label}
                        disableAnimation={true}
                    />
                ),
                disabled: isDisabled || disableButton ? true : undefined
            };
        });
    }

    private getPopOverConfig(coverPlans: LegacyCoverPlan[]): IPopOverConfig {
        const popOverConfig = {isPopOverDisplayed: false} as IPopOverConfig;
        const prefix = CmsHelper.getPrefix(this.props.cmsContext);
        const isAdobeScriptEnabledForJourney = AnalyticsHelper.isAdobeScriptEnabledForJourney(get(this.context.data, pathof(journeyBaseModel.journeyId)));
        const isAdobeTargetVariantB = get(this.context.data, pathof(journeyBaseModel.uiState.adobeTargetVariant)) === Constants.Analytics.Target.variantB;
        const isCarJourney = this.context?.data?.journeyId === Constants.Journey.Car.Id;

        if (isAdobeScriptEnabledForJourney && isAdobeTargetVariantB && isCarJourney) {
            // when variantB in car journey popOver is displayed
        } else if ((isAdobeScriptEnabledForJourney && isAdobeTargetVariantB) || isCarJourney) {
            // when variantB or car journey popOver is not displayed
            return popOverConfig;
        }

        for (const coverPlan of coverPlans) {
            const winnerPopOverText = this.props.intl.messages[`${prefix}.coverPlans.winner.popOver.${coverPlan.id}`];
            if (winnerPopOverText) {
                popOverConfig.isPopOverDisplayed = true;
                popOverConfig.id = coverPlan.id;
                popOverConfig.label = winnerPopOverText;
                break;
            }

        }

        return popOverConfig;
    }

}

const CompactCoverListTable = injectIntl(withViewContext(withCmsContextConsumer(CompactCoverListTableInternal)));
export {CompactCoverListTable};
